"use client";
import React, { useState, useCallback, useMemo, useEffect } from "react";

import { Box, Container, Grid, styled, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "next/navigation";

import { CountrySelectorChildrenProps } from "~/bff/components/CountrySelectorChildren";
import { FooterProps } from "~/bff/components/Footer";
import {
  CountrySelector,
  CountrySelectorPropsExtended,
} from "~/components/CountrySelector";
import { ROUTES } from "~/constants/routes";
import { COLOR_PRIMARY_WHITE } from "~/theme/constants/colors";
import { getFormatMedia } from "~/theme/utils/formatMedia";
import { getLocaleByCountryAndLanguage, getPageLocale } from "~/utils/locales";
import { getTestAutomationProps } from "~/utils/testUtils";

import { Copyright } from "./components/Copyright";
import { GroupedLinks } from "./components/GroupedLinks";
import { SocialNetworkLinks } from "./components/SocialNetworkLinks";
import { convertFooterToProps } from "./Footer.helper";

const PREFIX = "StyledFooter";
const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  contentWrapper: `${PREFIX}-contentWrapper`,
};

const StyledFooter = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);

  return {
    [`&.${classes.root}`]: {
      background: COLOR_PRIMARY_WHITE,
      paddingTop: theme.spacing(4),
      marginTop: theme.spacing(7),

      [formatMedia.BREAKPOINT_DESKTOP]: {
        paddingTop: theme.spacing(8),
        marginTop: theme.spacing(16),
      },
    },
    [`& .${classes.container}`]: {
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(4),

      [formatMedia.BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(9),
      },
    },
    [`& .${classes.contentWrapper}`]: {
      maxWidth: 1312,
    },
  };
});

export const Footer = (data: FooterProps) => {
  const { socialLinks, footerNavigation, copyright, countriesData } = useMemo(
    () => convertFooterToProps(data),
    [data]
  );

  const theme = useTheme();
  const { locale } = useParams();
  const CUSTOM_BREAK_POINT_MOBILE = "(max-width:700px)";
  const isMobileView = useMediaQuery(CUSTOM_BREAK_POINT_MOBILE);
  const isTabletView = useMediaQuery(theme.breakpoints.down("md"));
  const pageLocale = getPageLocale(locale as string);
  const [selectedCountry, setSelectedCountry] =
    useState<CountrySelectorChildrenProps>();

  useEffect(() => {
    setSelectedCountry(
      countriesData?.countries?.find(
        ({ countryKey, languageCode }) =>
          getLocaleByCountryAndLanguage(languageCode, countryKey) === pageLocale
      )
    );
  }, [countriesData?.countries, pageLocale]);

  const handleChange = useCallback<CountrySelectorPropsExtended["onChange"]>(
    (country) => {
      const { link, countryKey, languageCode } = country;
      const locale = getLocaleByCountryAndLanguage(languageCode, countryKey);

      setSelectedCountry(country);

      // this is a temporary solution as long as we only use a few localized content from amplience and the rest must be from the external url
      // verified with BA and customer
      const hasSelectorOuterLink = link?.url && link?.url.includes("http");

      if (hasSelectorOuterLink) {
        // eslint-disable-next-line no-restricted-globals
        open(link?.url as string, "_self");
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      open(`/${locale}${ROUTES.HOME}`, "_self");
    },
    []
  );

  return (
    <StyledFooter className={classes.root} {...getTestAutomationProps("footer")}>
      <Container className={classes.container}>
        <Grid className={classes.contentWrapper} container justifyContent="center">
          <SocialNetworkLinks socialLinks={socialLinks ?? []} />
          <GroupedLinks
            isTabletView={isTabletView}
            selectedCountry={selectedCountry}
            setSelectedCountry={handleChange}
            groupedLinks={footerNavigation ?? []}
            countries={countriesData?.countries ?? []}
            title={countriesData?.countrySelectorHeader?.title ?? ""}
            description={countriesData?.countrySelectorHeader?.description ?? ""}
          />
          {isTabletView && (
            <Grid item xs={12}>
              <CountrySelector
                value={selectedCountry}
                onChange={handleChange}
                isMobileView={isMobileView}
                countries={countriesData?.countries}
                title={countriesData?.countrySelectorHeader?.title}
                description={countriesData?.countrySelectorHeader?.description}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      <Copyright copyrightText={copyright ?? ""} />
    </StyledFooter>
  );
};
